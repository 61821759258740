.socials {
    position:absolute;
    bottom: 50px;
    left: 100px;
    
}

.socials a {
    font-size: 3rem;
    color: #ac0010;
    margin-right: 1rem;
}

.socials a:hover {
    color: #8d000e;
}