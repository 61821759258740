.navigation-link {
    font-size: 4rem;
    color: #ac0010;
    text-decoration: none;
}

.navigation-link.active {
    text-decoration: wavy underline;
}

.navigation-link.active:hover {
    text-decoration: wavy underline;
}

.navigation-link:hover {
    text-decoration: underline;
}
