.menu {
    list-style-type: none;
    position: fixed;
    right: 100px;
}

.disclaimer {
    position: fixed;
    bottom: 50px;
    right: 100px;
}