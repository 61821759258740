.list-entry-subtitle {
    font-size: 1.5rem;
    line-height: 1rem;
    display: block;
}

.list-entry-title {
    font-size: 2rem;
    line-height: 2rem;
    text-decoration: #ac0010 dotted underline;
}

.list-entry-description {
    font-size: 1rem;
    margin-top: .5rem;
    margin-bottom: 0;
}

.list-entry {
    margin-bottom: 50px;
}