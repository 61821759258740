.header {
    line-height: 9rem;
    background-color: white;
    width: fit-content;
}

.header.smaller {
    line-height: 4rem;
}

.header.smaller .subtitle {
    font-size: 2rem;
}

.header.smaller .title {
    font-size: 6rem;
}